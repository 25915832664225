import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Article = styled.article`
  h2 {
    font-size: 32px;
    font-weight: normal;
    line-height: 38px;
    padding-top: 62px;
  }

  header {
    padding: 0 0 56px;
  }

  header h1 {
    display: block;
    font-size: 32px;
    font-weight: normal;
    line-height: 38px;
  }

  p {
    margin: 62px 0 62px;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 42px;
      line-height: 50px;
      margin: 0 auto 0;
      padding: 138px 0 0 0;
      width: 50%;
    }

    header {
      margin: 0 0 64px;
    }

    header h1 {
      font-size: 62px;
      line-height: 72px;
      margin: 0 auto 0;
      width: 65%;
    }
  }

  @media (min-width: 992px) {
    header h1 {
      width: 50%;
    }
  }
`

const HeaderImg = styled(Img)`
  width: 100%;
  margin-bottom: 76px;

  @media (min-width: 768px) {
    display: block;
    margin: 0 auto 76px;
    width: 65%;
  }

  @media (min-width: 992px) {
    width: 50%;
  }
`

const GalleryImg = styled(Img)`
  width: calc(50% - 5px);

  @media (min-width: 768px) {
    width: calc(50% - 30px);
  }

  &:nth-child(even) {
    float: right;
  }
`

const About = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <Article>
      <header>
        <HeaderImg
          fluid={
            data.fbbHelleTrevinoWearingARedBikiniImage.childImageSharp.fluid
          }
          alt="professional female bodybuilder Helle Trevino"
        />
        <h1>I am…</h1>
        <h1>Those two words says a lot about me.</h1>
        <p>
          Great work stems from great processes. I live in the moment. I live in
          the now. The past is just my journey to the now, and the future has
          not arrived yet. I enjoy my life and I make my dreams become real. I
          create my own reality as if I was writing a novel. I go with the flow,
          and I believe all things are possible. I believe in the power of
          manifestation and a positive mindset.
        </p>
      </header>
      <GalleryImg
        fluid={data.helleTrevinoImage.childImageSharp.fluid}
        alt="Helle Trevino"
      />
      <GalleryImg
        fluid={
          data.helleTrevinoWearingAnOnePieceSwimsuitImage.childImageSharp.fluid
        }
        alt="Helle Trevino wearing an one piece swimsuit"
      />
      <h2>Discipline your mind…</h2>
      <p>
        When I was 10 I knew I wanted to become a professional athlete. And when
        I was 17 I knew I wanted to become a professional bodybuilder, and that
        I wanted to be the best in the World. I turned pro at 27, and I did go
        on to become the best in the world by winning the Rising Phoenix
        Worldchampionships, twice. I also knew early on that I wanted to be my
        own boss, so I started my online fitness coaching business in 2003.
        Since then I’ve expanded my business by hosting power of your mind
        seminars and training technique seminars around the world. I also offer
        private mental strength sessions in which I teach how to discipline the
        mind and strengthen it – just like you exercise a muscle to become
        stronger. I believe I was born with a strong mind, my upbringing
        cultivated it and competing in professional bodybuilding made it even
        stronger.
      </p>
      <GalleryImg
        fluid={data.helleTrevinoBackCalvesImage.childImageSharp.fluid}
        alt="Helle Trevino – Back & Calves"
      />
      <GalleryImg
        fluid={data.ifbbProHelleTrevinoImage.childImageSharp.fluid}
        alt="IFBB Pro Helle Trevino"
      />
      <h2>Giving back…</h2>
      <p>
        I love people and I love to work with people. I feel stagnant if I’m in
        the same place too long. Traveling and giving back to the world is in my
        blood and brings me joy. I happily participate in charity events or
        campaigning for a good cause. I spend as much time being out in the
        world as being inside my own world. I love yoga and meditation. It gives
        me peace and recharges my batteries. Bodybuilding is strength and power
        – a lot of yang energy. Yoga and meditation is the ability to be still
        and relaxed (soft) – yin energy. This combination is the perfect balance
        for me. I enjoy having friends around me. My friends are family.
      </p>
      <GalleryImg
        fluid={
          data.wingsOfStrengthRisingPhoenixWorldChampionHelleTrevinoImage
            .childImageSharp.fluid
        }
        alt="Wings of Strength Rising Phoenix women's bodybuilding world champion Helle Trevino"
      />
      <GalleryImg
        fluid={data.femaleBodybuilderHelleTrevinoImage.childImageSharp.fluid}
        alt="female bodybuilder Helle Trevino"
      />
      <h2>The future…</h2>
      <p>
        My future goals are to further the sport of women’s bodybuilding, and to
        fight for gender equality. I don’t conform to social norms or narrow
        boxes. I believe life is best lived in a positive fluent way embracing
        your own individuality and choosing your own happiness. I’ll continue to
        spread that message in the world. I’d like to win the Rising Phoenix
        World championship title again next year along with the Ms Olympia. My
        goal is to be the first woman in history to hold both titles. Lately
        I’ve started to work more in the entertainment business. I’ve worked on
        some documentaries, music videos and shows. That’s a path I’ll continue
        to follow and expand. Check out my{" "}
        <a
          href="https://onlyfans.com/helletrevino"
          rel="noreferrer"
          target="_blank"
        >
          OnlyFans page
        </a>{" "}
        and learn more about my life as a professional athlete. You can follow
        my journey to winning my third Rising Phoenix Worldchampionship and the
        Ms Olympia that is making its comeback this year.
      </p>
    </Article>
  </Layout>
)

export default About

export const query = graphql`
  query AboutDataQuery {
    fbbHelleTrevinoWearingARedBikiniImage: file(
      relativePath: { eq: "fbb-helle-trevino-wearing-a-red-bikini.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    helleTrevinoImage: file(relativePath: { eq: "helle-trevino.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    helleTrevinoWearingAnOnePieceSwimsuitImage: file(
      relativePath: { eq: "helle-trevino-wearing-an-one-piece-swimsuit.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    helleTrevinoBackCalvesImage: file(
      relativePath: { eq: "helle-trevino-back-calves.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ifbbProHelleTrevinoImage: file(
      relativePath: { eq: "ifbb-pro-helle-trevino.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wingsOfStrengthRisingPhoenixWorldChampionHelleTrevinoImage: file(
      relativePath: {
        eq: "wings-of-strength-rising-phoenix-world-champion-helle-trevino.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    femaleBodybuilderHelleTrevinoImage: file(
      relativePath: { eq: "female-bodybuilder-helle-trevino.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
